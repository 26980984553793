import React from 'react';
import { css } from '@linaria/core'
import { useSignupContext } from './SignupProvider'

import appleLogo from '../images/apple.svg'
import androidLogo from '../images/android.svg'
import texture from '../images/bg-texture.png';
import modalRight from '../images/bg-modal-right.png';
import modalLeft from '../images/bg-modal-left.png';

const doneCss = css`
  background: url('${texture}') center/cover;
  bottom: 0;
  color: #13294B;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  @media screen and (min-width: 800px) {
    align-items: center;
    color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .background {
    background: url('${modalLeft}') left top -50px no-repeat, url('${modalRight}') right -154px top no-repeat;
    bottom: 0;
    left: 0;
    overflow: auto;
    padding: 24px;
    position: absolute;
    right: 0;
    top: 0;

    @media screen and (min-width: 800px) {
      background: rgba(19, 41, 75, 0.9);
      backdrop-filter: blur(28px);
      padding: 60px;
    }
  }

  h1 { 
    font-family: 'Champion';
    font-style: normal;
    font-weight: 500;
    font-size: 55px;
    line-height: 51px;
    margin: 0 0 40px 0;
    text-align: center;

    @media screen and (min-width: 800px) {
      font-size: 88px;
      line-height: 84px;
      margin-bottom: 52px;
    }
  }

  h3 {
    color: #7BAFD4;
    font-family: 'Champion';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 24px;
  }

  .cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @media screen and (min-width: 800px) {
      align-items: stretch;
      flex-direction: row;
    }
  }

  .card {
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 12px 28px rgba(0, 0, 0, 0.35);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto 40px auto;
    max-width: 500px;
    padding: 24px;
    width: calc(100% - 48px);

    @media screen and (min-width: 800px) {
      border-radius: 40px;
      height: 500px;
      width: calc(50% - 36px);

      &:first-of-type {
        margin-right: 72px;
      }
    }
  }

  button {
    align-items: center;
    background: #7BAFD4;
    border: none;
    border-radius: 8px;
    color: #000;
    display: flex;
    font-family: 'Avenir Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    height: 56px;
    justify-content: center;
    cursor: pointer;
    width: 100%;

    img {
      margin-right: 12px;
    }
  }
  

  .card-button {
    margin-top: 24px;
    width: 100%;
  }

  .card-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    width: 100%;
    button {
      width: calc(50% - 8px);
    }
    button:first-of-type {
      margin-right: 16px;
    }
  }

  .card-content {
    color: #000;
    font-family: 'Avenir Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
  }

`

export default function Done () {
  const { profile } = useSignupContext()

  return (
    <div className={doneCss}>
      <div className="background">
        <h1>{profile?.firstName}, now that you are a member of the Rams Club, here are a few things we recommend.</h1>
        <div className="cards">
          <div className="card">
            <div className="card-content">
              <h3>We have a mobile app exclusively for Rams Club members.</h3>
              Get deals on exclusive akshhds asjhdjh fas. Get deals on exclusive aksh. Get deals on exclusive akshhds asjhdjh fasasa. Get deals on exclusive akshhds asjhdjh fas.
            </div>
            <div className="card-buttons">
              <button><img src={appleLogo} alt="Apple" /> Download</button>
              <button><img src={androidLogo} alt="Android" /> Download</button>
            </div>
          </div>
          <div className="card">
            <div className="card-content">
              <h3>Access your account in our web experience. </h3>
              Get deals on exclusive akshhds asjhdjh fas. Get deals on exclusive aksh. Get deals on exclusive akshhds asjhdjh fasasa. Get deals on exclusive akshhds asjhdjh fas.
            </div>
            <div className="card-button"><button>Login</button></div>
          </div>
        </div>
      </div>
    </div>
  )
}