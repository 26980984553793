import React, { useEffect } from 'react';
import { css } from '@linaria/core'
import { Formik, Form, Field, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { useSignupContext } from './SignupProvider'
import Stepper from './Stepper'

const phoneCss = css`
  [field-error='error'] input {
    border-color: red;
  }

  .error {
    color: red;
    margin-top: -20px;
  }
`

const schema = Yup.object().shape({
  phone: Yup.string()
    .required().min(12, 'Too Short!').matches(/[0-9]{3}-[0-9]{3}-[0-9]{4}/, 'Must be in ###-###-#### format.'),
  confirmPhone: Yup.string()
    .required().min(12, 'Too Short!').matches(/[0-9]{3}-[0-9]{3}-[0-9]{4}/, 'Must be in ###-###-#### format.').oneOf([Yup.ref('phone'), null], "Phone numbers don't match!"),
});

function UpdateStepper() {  
  const { isValid, dirty } = useFormikContext() || {}
  const { setReady } = useSignupContext()
  useEffect(()=> {
    setReady(isValid && dirty)
  }, [dirty, isValid, setReady])

  return null
}

export default function Mailing () {
  const { ready, profile, setProfile, next, currentStep } = useSignupContext()

  return (
    <div className={phoneCss}>
      <Formik
        initialValues={{
          phone: profile.phone,
          confirmPhone: profile.phone,
        }}
        validationSchema={schema}
        onSubmit={values => {
          // Do nothing since onClick of button is implemented
        }}
      >
        {({ errors, touched, values }) => {
          const onNext = () => {
            setProfile({...profile, ...values})
            next()
          }

          return (
            <Form>
              <div className="content">
                <header>
                  <h2>Nice. Look for something from us soon!</h2>
                  <h3>In case we have a question, what�s the best number to call?</h3>
                </header>
                <div className="fields">
                  <h4>Phone Number</h4>
                  <div className="phone" field-error={errors.phone ? 'error' : ''}>
                    <Field name="phone" placeholder="Phone (###-###-####)" maxLength={12} />
                    {touched.phone && errors.phone && <div className="error">{errors.phone}</div>}
                  </div>
                  <div className="phone" field-error={errors.phone ? 'error' : ''}>
                    <Field name="confirmPhone" placeholder="Confirm Phone" maxLength={12} />
                    {touched.confirmPhone && errors.confirmPhone && <div className="error">{errors.confirmPhone}</div>}
                  </div>
                </div>
                <div className="buttons">
                  <button type="button" onClick={onNext} disabled={!ready}>Next</button>
                  { currentStep?.showSkip && <button type="button" className="low-emphasis" onClick={()=> next()}>Skip</button>}
                </div>
              </div>
              <div className="stepper">
                <Stepper onNext={onNext} />
              </div>
              <UpdateStepper />
            </Form>
          )}}
      </Formik>
    </div>
  )
}