import React, { useCallback, useEffect, useState } from 'react';
import { css } from '@linaria/core'
import { Player } from '@lottiefiles/react-lottie-player';
import axios from 'axios';
import card from 'creditcard';
import qs from 'query-string';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from './credit-card-images'
import { FiLock } from 'react-icons/fi';
import { useSignupContext } from './SignupProvider'
import Stepper from './Stepper'

const { post } = axios

const API_HOST = 'https://api.campaign.nickeldxm.com';

const paymentCss = css`

  --Red: #d24f37;
  --Gray400: #bdbdbd;
  --Gray900: #212121;
  --White: #fff;

  .content[data-processing=true] header,
  .content[data-complete=true] header {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  h4 {
    margin: 0;
  }

  input {
    height: 54px;
  }

  .complete {
    margin-top: 50px;
  }

  .processing {
    margin-bottom: -110px
    maring-top: -110px;
  }


  .single-input-row {
    width: 100%;
  }

  .double-input-row {
    width: 100%;
    display: grid;
    grid-template: 1fr / 1fr 1fr;
    grid-gap: 24px;
  }

  .stripe-input {
    width: 100%;
    margin-bottom: 24px;
    padding: 8px 0px 8px 16px;
    box-sizing: border-box;
    color: var(--Gray900);
    background-color: var(--White);
    font-family: 'Avenir Regular';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    height: 56px;
    border: solid 1px var(--Gray400);
    border-radius: 8px;

    &::placeholder {
      color: #222;
      opacity: 0.5;
      font-family: Montserrat;
    }
    &:focus {
      outline: none;
    }
  }
  
  .secure-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .ssl-secured {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: var(--Gray400);

      .secure-icon {
        margin-right: 8px;
      }
    }
  }

  .error-text {
    font-size: 12px;
    color: var(--Red);
  }

  `

export default function Payment () {
  const { ready, setReady, payment, setPayment, profile, next } = useSignupContext()
  const [ processing, setProcessing ] = useState(false)
  const [ complete, setComplete ] = useState(false)
  const [ paymentToken, setPaymentToken ] = useState<string|null>('')
  const [ cardNumber, setCardNumber ] = useState()
  const [ expMonth, setExpMonth ] = useState()
  const [ expYear, setExpYear ] = useState()
  const [ cardCVV, setCardCVV ] = useState('')
  const [ cardZip , setCardZip ] = useState('')
  const [ cardBrand, setCardBrand ] = useState('')
  const [ cardLast4, setCardLast4 ] = useState('')
  const [ errorMessage, setErrorMessage] = useState('')

  const onNext = async () => {
    const paymentData = {
      ...payment,
      paymentToken,
      cardInfo: {
        brand: cardBrand,
        expMonth,
        expYear,
        cvv: cardCVV,
        zip: cardZip,
        last4: cardLast4
      }
    }
    
    setErrorMessage('')
    setPayment(paymentData)
    setProcessing(true)
    const signupPayload = {
      membershipId: '',
      payment: paymentData,
      profile,
    }

    try {
      await post(`${API_HOST}/v1/signup`, signupPayload)
      setProcessing(false)
      setComplete(true)
      setTimeout(() => {
        next()
      }, 3000)
    } catch (err) {
      setErrorMessage('Error processing. Please try again.')
      setProcessing(false)
    }
  }

const fetchPaymentToken = useCallback((setToken: (arg0: any) => void) => async (cardNumber: any) => {
  const formEncodedHeaders: Record<string, string | number | boolean> = { 'Content-Type': 'application/x-www-form-urlencoded' }
  try {
    setErrorMessage('')
    const {
      data: { temporaryPassword, endpoint },
    } = await axios.get(`${API_HOST}/v1/payment/auth`)
    const params = qs.stringify({
      accountNumber: cardNumber,
      callback: 'dontCallMe',
      password: temporaryPassword,
    })
    const { data: rawResponse } = await axios.post(endpoint, params, formEncodedHeaders)
    const token = rawResponse.split("accountNumber='")[1].replace("');", '')
    setToken(token)
    setReady(true)
  } catch (e) {
    setErrorMessage('Server Error')
  }
}, [setReady]);


const updateCardNumber = ({ target }:{target:any}) => setCardNumber(target.value)
const updateExpDate = ({ target }:{target:any}) => {
  const [expMonth, expYear] = target.value.split(' / ')
  setExpMonth(expMonth)
  setExpYear(expYear)
};
const updateCVV = ({ target }:{target:any}) => setCardCVV(target.value)
const updateCardZip = ({ target }:{target:any}) => setCardZip(target.value)

useEffect(() => {
  const validCard = card.validate(cardNumber)
  const validExp = card.expiry(expMonth, (expYear || '').padStart(4, '20'))
  const brand = card.cardscheme(cardNumber)
  const cardLast4 = card.truncate(cardNumber)
  const validCVV = brand === 'American Express' ? cardCVV.length === 4 : cardCVV.length === 3
  const validZip = cardZip.match(/^\d{5}$/)
  if (validCard && validExp && validCVV && validZip) {
    fetchPaymentToken(setPaymentToken)(cardNumber)
    setCardBrand(brand)
    setCardLast4(cardLast4)
  } else {
    setPaymentToken(null)
  }
}, [cardCVV, cardNumber, cardZip, expMonth, expYear, fetchPaymentToken])

const {
  wrapperProps,
  getCardImageProps,
  getCardNumberProps,
  getExpiryDateProps,
  getCVCProps,
  getZIPProps,
} = usePaymentInputs();

  return (
    <div className={paymentCss}>
      <div className="content" data-processing={processing} data-complete={complete} >
        { !processing && !complete && (<>
          <header>
            <h2>Great! I can help you with that.</h2>
            <h3>Please enter your payment information below.</h3>
          </header>
          <div className="fields">
            <div className="card-details">
              <div className="secure-container">
              <h4>Card Details</h4>
                <div className="ssl-secured">
                  <FiLock className="secure-icon" />
                  <div className="ssl-text">SSL Encrypted Secure Checkout </div>         
                </div>
              </div>
              <PaymentInputsWrapper
                {...wrapperProps}
                styles={{
                  fieldWrapper: { base: { height: 60, width: '100%', zIndex: 99 }, errored: {} },
                  inputWrapper: {
                    base: {
                      height: 56,
                      width: '100%',
                      paddingLeft: 16,
                      display: 'flex',
                      boxSizing: 'border-box',
                      borderRadius: 8,
                      border: 'solid 1px var(--Gray400)',
                      boxShadow: 'none',
                    },
                    focused: { borderWidth: 1, borderColor: 'var(--Gray400)', boxShadow: 'none' },
                    errored: { borderWidth: 1, borderColor: 'var(--Gray400)', boxShadow: 'none' },
                  },
                  errorText: { base: { color: 'var(--Red)' } },
                  input: { base: {}, errored: {}, cardNumber: {}, expiryDate: {}, cvc: {} }
                }}
              >
                <svg {...getCardImageProps({ images })} />
                <input {...getCardNumberProps({ onChange: updateCardNumber })} style={{ flex: 2 }} />
                <input {...getExpiryDateProps({ onChange: updateExpDate })} style={{ flex: 1 }} />
                <input {...getCVCProps({ onChange: updateCVV })} style={{ flex: 1 }} />
                <input {...getZIPProps({ onChange: updateCardZip })} style={{ flex: 1 }} />
              </PaymentInputsWrapper>
              { errorMessage && <div className="error-text">{errorMessage}</div> }
            </div>
          </div>
          <div className="buttons">
            <button type="button" onClick={onNext} disabled={!ready||processing}>Next</button>
          </div>
        </>)}
        { processing && (
          <header>
            <h2>Processing</h2>
            <div className="processing">
              <Player
                autoplay
                loop
                src="/animations/processing.json"
                style={{ height: '300px', width: '300px' }}
              />
            </div>
          </header>
        )}
        { complete && (
          <header>
            <h2>Complete</h2>
            <div className="complete">
              <Player
                autoplay
                loop
                src="/animations/complete.json"
                style={{ height: '120px', width: '120px' }}
              />
            </div>
          </header>
        )}
      </div>
      { !processing && !complete && <div className="stepper">
        <Stepper onNext={onNext} />
      </div>}
    </div>
  )
}