import React, { useEffect } from 'react';
import { css } from '@linaria/core'
import { Formik, Form, Field, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { useSignupContext } from './SignupProvider'
import Stepper from './Stepper'

const mailingCss = css`
  .address {
    display: flex;
    justify-content: space-between;

    div:first-of-type {
      margin-right: 16px;
    }

    div {
      width: calc(50% - 8px);
    }
  }

  .city-state-zip {
    display: flex;
    justify-content: space-between;
    
    & div {
      margin-right: 16px;
      width: calc(25% - 12px);
    }
  
    & div:first-of-type {
      width: calc(50% - 8px);
    }
  
    & div:last-of-type {
      margin-right: 0;
    }
}

  [field-error='error'] input {
    border-color: red;
  }

  .error {
    color: red;
    margin-top: -20px;
  }
`

const schema = Yup.object().shape({
  addressLine1: Yup.string()
    .min(2, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Required'),
  city: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  state: Yup.string()
    .min(2, 'Too Short!')
    .max(2, 'Too Long!')
    .required('Required'),
  zip: Yup.string()
    .min(5, 'Too Short!')
    .max(10, 'Too Long!')
    .required('Required')
});

function UpdateStepper() {  
  const { isValid, dirty } = useFormikContext() || {}
  const { setReady } = useSignupContext()
  useEffect(()=> {
    setReady(isValid && dirty)
  }, [dirty, isValid, setReady])

  return null
}

export default function Mailing () {
  const { ready, profile, setProfile, next, currentStep } = useSignupContext()

  return (
    <div className={mailingCss}>
      <Formik
        initialValues={{
          addressLine1: profile.addressLine1,
          addressLine2: profile.addressLine2,
          city: profile.city,
          state: profile.state,
          zip: profile.zip,
        }}
        validationSchema={schema}
        onSubmit={values => {
          // Do nothing since onClick of button is implemented
        }}
      >
        {({ errors, touched, values }) => {
          const onNext = () => {
            setProfile({...profile, ...values})
            next()
          }

          return (
            <Form>
              <div className="content">
                <header>
                  <h2>Welcome to the Rams Club, {profile.firstName}!</h2>
                  <h3>We’d like to send you a little welcome gift if you don’t mind?</h3>
                </header>
                <div className="fields">
                  <h4>Mailing Address</h4>
                  <div className="address">
                    <div field-error={errors.addressLine1 ? 'error' : ''}>
                      <Field name="addressLine1" placeholder="Street Address" />
                      {touched.addressLine1 && errors.addressLine1 && <div className="error">{errors.addressLine1}</div>}
                    </div>
                    <div field-error={errors.addressLine2 ? 'error' : ''}>
                      <Field name="addressLine2" placeholder="Apt # or Suite" />
                      {touched.addressLine2 && errors.addressLine2 && <div className="error">{errors.addressLine2}</div>}
                    </div>
                  </div>
                  <div className="city-state-zip">
                    <div field-error={errors.city ? 'error' : ''}>
                      <Field name="city" placeholder="City" />
                      {touched.city && errors.city && <div className="error">{errors.city}</div>}
                    </div>
                    <div field-error={errors.state ? 'error' : ''}>
                      <Field name="state" placeholder="State" maxLength="2" />
                      {touched.state && errors.state && <div className="error">{errors.state}</div>}
                    </div>
                    <div field-error={errors.zip ? 'error' : ''}>
                      <Field name="zip" placeholder="Zip" />
                      {touched.zip && errors.zip && <div className="error">{errors.zip}</div>}
                    </div>
                  </div>
                </div>
                <div className="buttons">
                  <button type="button" onClick={onNext} disabled={!ready}>Next</button>
                  { currentStep?.showSkip && <button type="button" className="low-emphasis" onClick={()=> next()}>No Thanks</button>}
                </div>
              </div>
              <div className="stepper">
                <Stepper onNext={onNext} />
              </div>
              <UpdateStepper />
            </Form>
          )}}
      </Formik>
    </div>
  )
}