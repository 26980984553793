import React, { useEffect, useState } from 'react';
import { css } from '@linaria/core'
import { Formik, Form, Field, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { useSignupContext } from './SignupProvider'
import Stepper from './Stepper'

import shown from '../images/shown.svg';
import hidden from '../images/hidden.svg';

const accountCss = css`
  .names {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: 800px) {
      flex-direction: row;
      & > div:first-of-type {
        margin-right: 16px;
      }
      & > div {
        width: calc(50% - 8px);
      }
    }
  }

  .passwords {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: 800px) {
      flex-direction: row;
      & > div:first-of-type {
        margin-right: 16px;
      }
      & > div {
        width: calc(50% - 8px);
      }
    }

    .password {
      position: relative;

      & > button {
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        border: none;
        border-radius: 0;
        color: transparent;
        height: 24px;
        padding: 0;
        position: absolute;
        right: 10px;
        top: 16px;
        width: 24px;
      }
    }
  }

  [field-error='error'] input {
    border-color: red;
  }

  .error {
    color: red;
    margin-top: -20px;
  }
`

const accountSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .min(4, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  confirmPassword: Yup.string()
    .min(4, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required').oneOf([Yup.ref('password'), null], "Passwords don't match!")
});

function UpdateStepper() {  
  const { isValid, dirty } = useFormikContext() || {}
  const { setReady } = useSignupContext()
  useEffect(()=> {
    setReady(isValid && dirty)
  }, [dirty, isValid, setReady])

  return null
}

export default function AccountDetails () {
  const [showPassword, setShowPassword] = useState(false)
  const { ready, profile, setProfile, next } = useSignupContext()

  return (
    <div className={accountCss}>
        <Formik
        initialValues={{
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
          password: profile.password,
          confirmPassword: profile.password,
        }}
        validationSchema={accountSchema}
        onSubmit={values => {
          // Do nothing since onClick of button is implemented
        }}
      >
        {({ errors, touched, values }) => {
          const onNext = () => {
            setProfile({...profile, ...values})
            next()
          }

          return (
            <Form>
              <div className="content">
                <header>
                  <h2>Rameses - Recent Graduate</h2>
                  <h3>First, let's create your account.</h3>
                </header>
                <div className="fields">
                  <h4>Account Details</h4>
                  <div className="names">
                    <div className="name" field-error={errors.firstName ? 'error' : ''}>
                      <Field name="firstName" placeholder="First Name" />
                      {touched.firstName && errors.firstName && <div className="error">{errors.firstName}</div>}
                    </div>
                    <div className="name" field-error={errors.lastName ? 'error' : ''}>
                      <Field name="lastName" placeholder="Last Name" />
                      {touched.lastName && errors.lastName && <div className="error">{errors.lastName}</div>}
                  </div>
                  </div>
                  <div className="email" field-error={errors.email ? 'error' : ''}>
                    <Field name="email" placeholder="Email Address" />
                    {touched.email && errors.email && <div className="error">{errors.email}</div>}
                  </div>
                  <div className="passwords">
                    <div className="password" field-error={errors.password ? 'error' : ''}>
                      <Field name="password" placeholder="Password" type={showPassword?'text':'password'} onBlur={()=>setShowPassword(false)} /><button tabIndex={-1} style={{backgroundImage: `url('${showPassword?shown:hidden}')`}} onClick={()=>setShowPassword(!showPassword)}></button>
                      {errors.password && <div className="error">{errors.password}</div>}
                    </div>
                    <div className="password" field-error={errors.confirmPassword ? 'error' : ''}>
                      <Field name="confirmPassword" placeholder="Confirm Password" type={showPassword?'text':'password'} onBlur={()=>setShowPassword(false)} /><button tabIndex={-1} style={{backgroundImage: `url('${showPassword?shown:hidden}')`}} onClick={()=>setShowPassword(!showPassword)}></button>
                      {errors.confirmPassword && <div className="error">{errors.confirmPassword}</div>}
                    </div>
                  </div>
                </div>
                <div className="buttons">
                  <button type="button" onClick={onNext} disabled={!ready}>Next</button>
                  <button type="button" className="low-emphasis">Existing Members Click Here</button>
                </div>
              </div>
              <div className="stepper">
                <Stepper onNext={onNext} />
              </div>
              <UpdateStepper />
            </Form>
          )}}
      </Formik>
    </div>
  )
}