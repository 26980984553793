import React, { useEffect, useState } from 'react';
import { css } from '@linaria/core'
import { useSignupContext } from './SignupProvider'

const stepperCss = css`
  align-items: center;
  display: flex;
  height: 16px;
  justify-content: space-between;
  padding: 0 50px;
  width: 100%;

  .bar {
    align-items: center;
    background: #7BAFD4;
    box-shadow: 0px 0px 5px rgba(19, 41, 75, 0.3);
    display: flex;
    height: 3px;
    justify-content: space-between;
    overflow: visible;
    width: calc(100% - 136px);
  }

  .step {
    background: #13294B;
    border: 2px solid #13294B;
    border-radius: 50%;
    box-sizing: border-box;
    height: 10px;
    margin: -5px;
    width: 10px;
  }

  .step.done,
  .step.current {
    background: #7BAFD4;
    height: 16px;
    margin: -8px;
    width: 16px;
  }

  .step.current {
    background: #FFFFFF;
  }


  .next,
  .previous {
    flex-grow: 1;
    width: 68px;

    .button {
      align-items: center;
      background: none;
      border: none;
      color: #13294B;
      cursor: pointer;
      display: flex;
      font-family: 'Avenir Regular';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      height: 16px;
      justify-content: center;
      line-height: 14px;
      text-align: center;
      width: 100%;

      &[disabled] {
        color: #A2AAAD;
      }
    }
  }
`

export default function Stepper ({ onNext }:{onNext: Function}) {
  const [showBack, setShowBack] = useState(false)
  const [showSkip, setShowSkip] = useState(false)
  const [enableNext, setEnableNext] = useState(false)
  const { currentStep, steps, previous, next } = useSignupContext()
  const lastStep = currentStep && (steps.indexOf(currentStep) === steps?.length - 1)
  const [showNext, setShowNext] = useState(!lastStep)
 
  useEffect(()=>{
    setShowBack(!!currentStep?.showBack)
  }, [currentStep?.showBack])

  useEffect(()=>{
    setShowSkip(!!currentStep?.showSkip)
  }, [currentStep?.showSkip])

  useEffect(()=>{
    setShowNext(!lastStep)
  }, [lastStep])

  useEffect(()=>{
    setEnableNext(showSkip || !!currentStep?.ready)
  }, [showSkip, currentStep?.ready])

  return (
    <div className={stepperCss}>
      <div className="previous">{ showBack && <button type="button" className="button" onClick={()=>previous()}>Back</button>}</div>
      <div className="bar">
        { steps.map((step) => {
          return (<div key={step.name} className={`step ${step.current? 'current': ''} ${step.done? 'done': ''}`}></div>)
        })}
      </div>
      <div className="next">{ showNext && <button type="button" className="button" disabled={!enableNext} onClick={()=>{ 
        onNext && onNext(() => next())
      }}>{ showSkip ? 'Skip' : 'Next' }</button>}</div>
    </div>
  )
}