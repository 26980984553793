import React from 'react';
import SignupApp from './components/SignupApp';
import SignupProvider from './components/SignupProvider';

function App() {
  return (
    <SignupProvider>
      <SignupApp />
    </SignupProvider>
  );
}

export default App;
