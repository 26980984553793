import React from 'react'
import { css } from '@linaria/core'
import AccountDetails from './AccountDetails'
import Payment from './Payment'
import PaymentPlan from './PaymentPlan'
import Mailing from './Mailing'
import Phone from './Phone'
import Done from './Done'
import { useSignupContext } from './SignupProvider'

import texture from '../images/bg-texture.png';
import left from '../images/bg-left.png';
import right from '../images/bg-right.png';
import modalRight from '../images/bg-modal-right.png';
import modalLeft from '../images/bg-modal-left.png';

const appCss = css`
  align-items: center;
  background: url('${texture}') center/cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  .main {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  
    @media screen and (min-width: 800px) {
      align-items: center;
      background: url('${left}') left top no-repeat, url('${right}') right top no-repeat;
      display: flex;
      flex-direction: column;
      height: 100vh;
      justify-content: center;
      max-height: 1024px;
      position: relative;
    }
  }

  .main[data-is-done] {
    @media screen and (min-width: 800px) {
      height: 100vh;
      max-height: 100vh;
    }
  }

  .modal {
    background: url('${texture}') center/cover;
    height: 100vh;
    overflow: auto;
    position: relative;

    @media screen and (min-width: 800px) {
      background: #FFFFFF;
      border-radius: 24px;
      filter: drop-shadow(0px 24px 48px rgba(0, 0, 0, 0.24));
      height: 630px;
      overflow: visible;
      width: 656px;
    }

    .stepper {
      align-items: center;
      position: absolute;
      display: flex;
      height: 16px;
      justify-content: center;
      left: 0;
      padding: 0 24px;
      right: 0;
      top: 40px;

      @media screen and (min-width: 800px) {
        bottom: -40px;
        top: inherit;
      }
    }

    .content {
      background: url('${modalLeft}') left top -50px no-repeat, url('${modalRight}') right -154px top no-repeat;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      left: 0;
      overflow: auto;
      padding: 24px;
      position: absolute;
      right: 0;
      top: 60px;

      @media screen and (min-width: 800px) {
        background: url('${modalLeft}') left bottom no-repeat, url('${modalRight}') right -154px bottom -244px no-repeat;
        border-radius: 24px;
        overflow: hidden;
        padding: 44px 56px 44px 44px;
        top: 0px;
      }

      .buttons {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        @media screen and (min-width: 800px) {
          flex-direction: row;
        }

        button {
          align-items: center;
          background: #7BAFD4;
          border: none;
          border-radius: 8px;
          color: #000000;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          font-family: 'Avenir Regular', Verdana;
          font-size: 18px;
          font-style: normal;
          font-weight: bold; /* 700 */
          line-height: 25px;
          justify-content: center;
          padding: 16px 40px;
          text-align: center;
          width: 100%;

          @media screen and (min-width: 800px) {
            width: inherit;
          }
        }

        button[disabled] {
          background-color: #ccc;
          color: #999;
        }

        button.low-emphasis {
          background: transparent;
          padding: 16px 30px;
        }
      }

      @media screen and (min-width: 800px) {
        background: url('${modalRight}') right -126px center no-repeat;
      }
    }
  }
`

export default function SignupApp() {
  const { currentStep } = useSignupContext()
  return currentStep?.name === 'Done' ? (<div className={appCss}><div className="main" data-is-done><Done /></div></div>) : (<div className={appCss}>
      <div className="main">
        <div className="modal">
          {currentStep?.name === 'Account' && <AccountDetails />}
          {currentStep?.name === 'Payment Plan' && <PaymentPlan />}
          {currentStep?.name === 'Payment' && <Payment />}
          {currentStep?.name === 'Mailing' && <Mailing />}
          {currentStep?.name === 'Phone' && <Phone />}
        </div>
      </div>
    </div>)
}