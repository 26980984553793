import React, { useState } from 'react';
import { css } from '@linaria/core'

import { useSignupContext } from './SignupProvider'
import Stepper from './Stepper'
import { calculateMonthly, formatMoney } from '../utils/payments'

import radioOff from '../images/radio-off.svg';
import radioOn from '../images/radio-on.svg';

const planCss = css`
  .option {
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #8D9092;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    height: 56px;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 0 12px 0 18px;
    width: 100%;

    &:focus {
      border-color: #7BAFD4;
    }
  }
`

export default function PaymentPlan () {
  const { ready, setReady, next, profile, payment, setPayment, membershipTotal = 0 } = useSignupContext()
  const [monthly, setMonthly] = useState(payment?.monthlyPayment)

  const onNext = () => {
    setPayment({...payment, monthlyPayment: monthly})
    next()
  }
  return (
    <div className={planCss}>
      <div className="content">
        <header>
          <h2>Nice to meet you, {profile.firstName}.</h2>
          <h3>What option is best for you?</h3>
        </header>
        <div className="fields">
          <h4>Choose Plan</h4>
          <div className="options">
            <button className="option" onClick={() => { 
              setMonthly(false)
              setReady(true)
            }}>
              One-Time (${formatMoney(membershipTotal)})
              <img src={monthly===false?radioOn:radioOff} alt="One-Time" />
            </button>
            <button className="option" onClick={() => {
              setMonthly(true)
              setReady(true)
            }}>
              Monthly (${formatMoney(calculateMonthly(membershipTotal))})
              <img src={monthly===true?radioOn:radioOff} alt="Monthly" />
            </button>
          </div>
        </div>
        <div className="buttons">
          <button type="button" onClick={onNext} disabled={!ready}>Next</button>
        </div>
      </div>
      <div className="stepper">
        <Stepper onNext={onNext} />
      </div>
    </div>
  )
}