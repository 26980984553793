import React, { useContext, useState } from 'react';

interface Step {
  id: string,
  name: string,
  showBack: boolean,
  backUrl?: string,
  showSkip: boolean,
  done: boolean,
  current: boolean,
  ready: boolean
}

interface Profile {
  addressLine1?: string,
  addressLine2?: string,
  city?: string,
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  state?: string,
  zip?: string,
  phone?: string
}

interface Payment {
  paymentAmount?: Number,
  monthlyPayment?: boolean,
  paymentToken?: string,
  cardInfo?: {brand: string, expMonth: Number, expYear: Number, zip: string},
  last4?: string
}

interface Context {
  steps: Step[],
  profile: Profile,
  setProfile: Function,
  payment: Payment,
  setPayment: Function,
  getStep: Function,
  next: Function,
  previous: Function,
  ready: boolean,
  setReady: Function,
  currentStep?: Step,
  membershipTotal?: number
}

const defaultSteps: Step[] = [
  {
    id: 'account',
    name: 'Account',
    showBack: true,
    backUrl: 'https://ramsclub.com/membership/',
    showSkip: false,
    done: false,
    current: true,
    ready: false,
  },
  {
    id: 'plan',
    name: 'Payment Plan',
    showBack: true,
    showSkip: false,
    done: false,
    current: false,
    ready: false,
  },
 {
    id: 'payment',
    name: 'Payment',
    showBack: true,
    showSkip: false,
    done: false,
    current: false,
    ready: false,
  },
  {
    id: 'mailing',
    name: 'Mailing',
    showBack: false,
    showSkip: true,
    done: false,
    current: false,
    ready: false,
  },
  {
    id: 'phone',
    name: 'Phone',
    showBack: true,
    showSkip: true,
    done: false,
    current: false,
    ready: false,
  },
  {
    id: 'done',
    name: 'Done',
    showBack: false,
    showSkip: false,
    done: false,
    current: false,
    ready: false,
  },
]

const defaultProfile: Profile = {
  email: '',
  password: '',
  firstName: '',
  lastName: ''
}


const SignupContext = React.createContext<Context>({ 
  steps: defaultSteps,
  currentStep: defaultSteps[0],
  profile: defaultProfile,
  setProfile: () => {},
  payment: {},
  setPayment: () => {},
  getStep: () => {},
  next: ()=>{},
  previous: () => {},
  ready: false,
  setReady: () => {}
});

type Props = {
  children?:
    | React.ReactChild
    | React.ReactChild[];
}

export default function SignupProvider({ children }: Props): React.ReactElement<any, any> {
  const [steps, setSteps] = useState(defaultSteps)
  const [payment, setPayment] = useState<Payment>({})
  const [profile, setProfile] = useState<Profile>(defaultProfile)
  const [currentStep, setCurrentStep] = useState<Step>(defaultSteps[0])
  const [ready, setReady] = useState(false)
  const [membershipTotal] = useState(700)

  const next = () => {
    const stepIndex = steps.indexOf(currentStep)
    if(stepIndex < steps.length) {
      currentStep.done = true
      currentStep.current = false
      const newCurrent = steps[stepIndex+1]
      newCurrent.current = true
      setCurrentStep(newCurrent)
    }
  }

  const previous = () => {
    const stepIndex = steps.indexOf(currentStep)
    if(stepIndex > 0) {
      currentStep.current = false
      const newCurrent = steps[stepIndex-1]
      newCurrent.current = true
      setCurrentStep(newCurrent)
    }
  }

  const value = {
    currentStep,
    next,
    previous,
    steps,
    setSteps,
    getStep: (name: string) => steps.find((step)=>step.name === name),
    profile,
    setProfile,
    payment,
    setPayment,
    ready,
    membershipTotal,
    setReady: (val: boolean) => {
      currentStep.ready = val
      setReady(val)
    }
  }

  return (
    <SignupContext.Provider value={value}>{children}</SignupContext.Provider>
  )
}

export function useSignupContext() {
  return useContext(SignupContext)
}